import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Admin from './components/Admin';
import Revista from './components/Revista';
import PreviousAdmin from './components/PreviousAdmin';
import PreviousRevista from './components/PreviousRevista';
import './App.css';
import GoogleAuth from './components/GoogleAuth';
import { ModalProvider } from './components/ModalContext';
import Modal from './components/Modal';

function App() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        const storedUser = sessionStorage.getItem('user');
        if (storedUser) {
            setUser(JSON.parse(storedUser));
        }
    }, []);

    const ProtectedRoute = ({ children }) => {
        if (!user) {
            return <GoogleAuth onLogin={setUser} />;
        }
        return children;
    };

    return (
        <Router>
            <div className="App">
                <ModalProvider>
                    <main>
                        <Modal />
                        <Routes>
                            <Route path="/" element={<Revista />} />
                            <Route path="/admin" element={
                                <ProtectedRoute>
                                    <Admin />
                                </ProtectedRoute>
                            } />

                            <Route path="/anterior" element={<PreviousRevista />} />
                            <Route path="/anterior/admin" element={
                                <ProtectedRoute>
                                    <PreviousAdmin />
                                </ProtectedRoute>
                            } />
                        </Routes>
                    </main>
                </ModalProvider>
            </div>
        </Router>
    );
}

export default App;
