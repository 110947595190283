import React from 'react';
import Logo from '../public/IDC_LOGO.png';

function Header() {
    return (
        <header className="fixed top-0 left-0 right-0 z-50 flex flex-col items-center w-full bg-gradient-to-r from-[#000000] via-[#141414] to-[#111111] md:flex-row md:justify-between py-2">
            <img src={Logo} alt="Logo" className="h-auto w-40 md:w-52 md:h-28 object-cover"/>
        </header>
    );
}

export default Header;
