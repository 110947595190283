import React from 'react';
import { useModal } from './ModalContext';

const Modal = () => {
    const { isOpen, closeModal, text, showPrintLater, onConfirm } = useModal();

    if (!isOpen) return null;

    return (
        <div
            tabIndex="-1"
            aria-hidden="true"
            className="fixed inset-0 z-50 flex items-center justify-center w-full h-full bg-black bg-opacity-30 backdrop-blur-md"
        >
            <div className="relative w-full max-w-2xl p-4 max-h-full">
                <div className="relative rounded-lg shadow bg-white">
                    <div className="flex items-center justify-between p-4 border-b rounded-t md:p-5 bg-black">
                        <h3 className="text-xl font-semibold text-white">
                            Aviso
                        </h3>
                        <button
                            onClick={closeModal}
                            type="button"
                            className="inline-flex items-center justify-center w-8 h-8 text-sm text-gray-400 bg-transparent rounded-lg hover:bg-gray-200 hover:text-gray-900"
                        >
                            <svg
                                className="w-3 h-3"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 14 14"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M1 1l6 6m0 0l6 6M7 7l6-6M7 7l-6 6"
                                />
                            </svg>
                            <span className="sr-only">Cerrar modal</span>
                        </button>
                    </div>
                    <div className="p-4 space-y-4 md:p-9">
                        <p className="leading-relaxed text-gray-700 text-xl">
                            {text}
                        </p>
                    </div>
                    <div className="flex items-center justify-center p-4 border-t border-gray-200 rounded-b md:p-5">
                    <button onClick={() => {
                            closeModal(); 
                            onConfirm && onConfirm(); 
                        }} 
                        type="button" 
                        className="px-5 py-2.5 text-sm font-medium text-white bg-black rounded">
                          Aceptar
                        </button>
                        {showPrintLater && (
                            <button onClick={closeModal} type="button" className="ml-4 px-5 py-2.5 text-sm font-medium text-gray-700 bg-gray-200 rounded">Imprimir en otro momento</button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
