import React, { useState, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Worker, Viewer, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { printPlugin } from '@react-pdf-viewer/print';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import 'tailwindcss/tailwind.css';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import { useModal } from './ModalContext';
import Modal from './Modal';

function Revista() {
    const [canPrint, setCanPrint] = useState(false);
    const [isPdfVisible, setIsPdfVisible] = useState(false);
    const [pdfUrl, setPdfUrl] = useState('');
    const [coverUrl, setCoverUrl] = useState('');
    const [modalText, setModalText] = useState('');
    const [isPrintSuccessVisible, setIsPrintSuccessVisible] = useState(false);
    const navigate = useNavigate();
    const { showModalWithText } = useModal();
    const [isLoading, setIsLoading] = useState(true);
    const [isImageLoading, setIsImageLoading] = useState(true); 

    useEffect(() => {
        checkUserStatus();
    }, []);

    useEffect(() => {
        const fetchCoverUrl = async () => {
            try {
                const response = await fetch('https://m0uxfja3eb.execute-api.us-west-2.amazonaws.com/prod/api/v1/get-current-thumbnail');
                const { url } = await response.json();
                setCoverUrl(url);
            } catch (error) {
                setCoverUrl('https://idc.brightspotcdn.com/dims4/default/c2751b6/2147483647/thumbnail/400x517%5E/quality/90/?url=https%3A%2F%2Fidc.brightspotcdn.com%2F17%2F79%2F956a03194c2e94529c91c37cddce%2Fidc-562.jpg');
                console.error('Error fetching cover URL:', error);
            }
        };
    
        fetchCoverUrl();
    }, []);
    
    useEffect(() => {
        loadPdf();
    }, []);

    const getUserId = () => {
        let userId = localStorage.getItem('userId');
        if (!userId) {
            userId = uuidv4();
            localStorage.setItem('userId', userId);
        }
        return userId;
    };

    const checkUserStatus = async () => {
        try {
            const userId = getUserId();
            const response = await fetch(`https://m0uxfja3eb.execute-api.us-west-2.amazonaws.com/prod/api/v1/download-status/${userId}`);
            if (!response.ok) {
                throw new Error(`Error en la solicitud: ${response.status}`);
            }
            const downloadStatus = await response.json();
            const versionResponse = await fetch('https://m0uxfja3eb.execute-api.us-west-2.amazonaws.com/prod/api/v1/version');
            if (!versionResponse.ok) {
                throw new Error(`Error en la solicitud: ${versionResponse.status}`);
            }
            const versionData = await versionResponse.json();

            if (versionData) {
                if (downloadStatus && downloadStatus.version === versionData.version) {
                    setCanPrint(false);
                } else {
                    setCanPrint(true);
                }
            }
        } catch (error) {
            console.error('Error al obtener el estado del usuario:', error);
            showModalWithText('No existe una versión activa, si crees que esto es un error, por favor comunícate con el área de Atención al Cliente 55.4440.2293.', null, false);
        }
    };

    const loadPdf = async () => {
        try {
            const versionResponse = await fetch('https://m0uxfja3eb.execute-api.us-west-2.amazonaws.com/prod/api/v1/version');
            if (!versionResponse.ok) {
                throw new Error(`Error en la solicitud: ${versionResponse.status}`);
            }
            const versionData = await versionResponse.json();

            if (!versionData || !versionData.fileName) {
                throw new Error('No se encontró una versión activa del PDF');
            }

            const response = await fetch(`https://m0uxfja3eb.execute-api.us-west-2.amazonaws.com/prod/api/v1/files/${versionData.fileName}`);
            if (!response.ok) {
                throw new Error(`Error al obtener la URL firmada: ${response.status}`);
            }
            const { url } = await response.json();

            if (!url) {
                throw new Error('No se encontró la URL del PDF');
            }

            setPdfUrl(url);
        } catch (error) {
            console.error('Error al cargar el PDF:', error);
            showModalWithText('No existe una versión activa, si crees que esto es un error, por favor comunícate con el área de Atención al Cliente 55.4440.2293.', null, false);
        }
    };

    const handlePrint = async (instance) => {
        if (!canPrint) return;
        showModalWithText(
            'Una vez que hagas clic en "Aceptar", no podrás realizar esta acción nuevamente. Recuerda que es importante enviar el documento completo, ya que si se envía de forma parcial, esto se contará como su única impresión.',
            () => {
                const confirmPrint = () => {
                    instance.print();

                    window.onafterprint = async () => {
                        const userId = getUserId();
                        const versionResponse = await fetch('https://m0uxfja3eb.execute-api.us-west-2.amazonaws.com/prod/api/v1/version');
                        const versionData = await versionResponse.json();

                        if (versionData) {
                            await fetch('https://m0uxfja3eb.execute-api.us-west-2.amazonaws.com/prod/api/v1/register-download', {
                                method: 'POST',
                                headers: { 'Content-Type': 'application/json' },
                                body: JSON.stringify({ userId, version: versionData.version }),
                            });
                            setCanPrint(false);
                            setIsPrintSuccessVisible(true);
                            showModalWithText('El documento ha sido impreso exitosamente. Esta acción no se puede repetir. Si tienes alguna duda, por favor comunícate con el área de Atención al Cliente al teléfono 55.4440.2293.', null, false);
                        }
                    };
                };
                confirmPrint();
            },
            true
        );
    };

    const printPluginInstance = printPlugin();

    return (
        <div className="flex flex-col items-center justify-center min-h-screen pt-20 bg-gray-200">
            <Header />
            {!isPdfVisible && (
                <div className="relative my-4 flex flex-col items-center">
                    <p className="mb-4 text-center text-2xl font-semibold leading-normal text-gray-600">
                        Haz clic en la portada para imprimir la revista.
                    </p>
                    {isImageLoading && (
                        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-gray-500 mb-4"></div>
                    )}
                    <img
                        src={coverUrl}
                        alt="Magazine Cover"
                        className={`w-60 h-auto md:w-80 rounded-lg shadow-lg transform transition-opacity duration-500 ease-in-out ${isImageLoading ? 'opacity-0' : 'opacity-100'} cursor-pointer`}
                        onLoad={() => setIsImageLoading(false)} 
                        onClick={() => setIsPdfVisible(true)}
                    />
                </div>
            )}

            {isPdfVisible && pdfUrl && (
                <div className="w-full max-w-3xl flex flex-col items-center p-4 bg-white rounded-lg shadow-lg overflow-auto mt-4 space-y-4">
                    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js">
                        <div className="w-full h-full pdf-viewer-container rounded-lg shadow-md overflow-auto max-h-96">
                            <Viewer
                                fileUrl={pdfUrl}
                                defaultScale={SpecialZoomLevel.PageWidth}
                                plugins={[printPluginInstance]}
                            />
                        </div>
                    </Worker>
                    <button
                        onClick={() => handlePrint(printPluginInstance)}
                        className={`w-full max-w-xs py-2 px-4 rounded-md shadow hover:opacity-80 focus:outline-none ${canPrint ? 'bg-gradient-to-r from-[#000000] via-[#141414] to-[#111111] text-white' : 'bg-gray-400 text-gray-700 cursor-not-allowed'}`}
                        disabled={!canPrint}
                    >
                        {canPrint ? "Imprimir" : "Ya has impreso esta versión"}
                    </button>
                    <button
                        onClick={() => {
                            navigate('/');
                            window.location.reload();
                        }}
                        className="w-full max-w-xs py-2 px-4 bg-gradient-to-r from-[#000000] via-[#141414] to-[#111111] text-white rounded-md shadow hover:opacity-80 focus:outline-none"
                    >
                        Volver
                    </button>
                </div>
            )}

            <Modal text={modalText} />
        </div>
    );
}

export default Revista;
