import React from 'react';
import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

const clientId = '1031736981459-ogqhoe5nvr48nfvoq868a1209srcvhpq.apps.googleusercontent.com';


function GoogleAuth({ onLogin }) {
    const handleLoginSuccess = (credentialResponse) => {
        const decoded = jwtDecode(credentialResponse.credential);
        const domain = decoded.email.split('@')[1];

        if (domain === 'idconline.mx') {
            sessionStorage.setItem('user', JSON.stringify(decoded));
            onLogin(decoded);
        } else {
            alert('Acceso denegado: Solo los usuarios de tuempresa.com pueden acceder.');
        }
    };

    const handleLoginFailure = () => {
        alert('Error al iniciar sesión');
    };

    return (
        <div className="flex justify-center items-center h-screen bg-gray-100">
            <GoogleOAuthProvider clientId={clientId}>
                <GoogleLogin
                    onSuccess={handleLoginSuccess}
                    onError={handleLoginFailure}
                    text="continue_with"
                    shape="rectangular"
                    theme="filled_blue"
                    width="240"
                />
            </GoogleOAuthProvider>
        </div>
    );
}

export default GoogleAuth;