import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext();

export const useModal = () => useContext(ModalContext);

export const ModalProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [text, setText] = useState('');
    const [onConfirm, setOnConfirm] = useState(null);
    const [showPrintLater, setShowPrintLater] = useState(false);

    const openModal = () => setIsOpen(true);
    const closeModal = () => {
        setIsOpen(false);
        setShowPrintLater(false);
        setOnConfirm(null); 
    };
    const setModalText = (text) => setText(text);
    
    const showModalWithText = (text, onConfirmAction, showPrintLaterButton = false) => {
        setText(text);
        setOnConfirm(() => onConfirmAction); 
        setShowPrintLater(showPrintLaterButton);
        openModal();
    };

    return (
        <ModalContext.Provider value={{ isOpen, openModal, closeModal, setModalText, showModalWithText, text, onConfirm, showPrintLater }}>
            {children}
        </ModalContext.Provider>
    );
};