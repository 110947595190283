import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from '../public/IDC_LOGO.png';
import { useModal } from './ModalContext';
import Modal from './Modal';

function Admin() {
    const [file, setFile] = useState(null);
    const [thumbnailFile, setThumbnailFile] = useState(null);
    const [uploadMessage, setUploadMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { showModalWithText } = useModal();

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleThumbnailFileChange = (e) => {
        setThumbnailFile(e.target.files[0]);
    };

    const uploadFile = async () => {
        setLoading(true);

        if (!file) {
            showModalWithText('¡Selecciona un archivo PDF primero!', () => {}, false);
            setLoading(false);
            return;
        }

        const fileName = file.name;

        try {
            const getSignedUrlResponse = await fetch('https://m0uxfja3eb.execute-api.us-west-2.amazonaws.com/prod/api/v2/generate-upload-url', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ fileName, contentType: file.type }),
            });

            const signedUrlData = await getSignedUrlResponse.json();
            if (!signedUrlData.success) {
                showModalWithText('Error al generar URL firmada', null, false);
                setLoading(false);
                return;
            }

            const { url: signedUrl } = signedUrlData;

            const uploadResponse = await fetch(signedUrl, {
                method: 'PUT',
                headers: { 'Content-Type': file.type },
                body: file,
            });

            if (uploadResponse.ok) {
                showModalWithText('Archivo cargado exitosamente', null, false);
            } else {
                showModalWithText('Error al subir el archivo a S3', null, false);
            }
        } catch (error) {
            console.error('Error:', error);
            showModalWithText('Ocurrió un error durante la carga', null, false);
        } finally {
            setLoading(false);
        }
    };

    const handleThumbnailUpload = async () => {
        setLoading(true);

        if (!thumbnailFile) {
            showModalWithText('¡Selecciona una imagen primero!', null, false);
            setLoading(false);
            return;
        }

        const reader = new FileReader();
        reader.onloadend = async () => {
            const fileContent = reader.result.split(',')[1];

            try {
                const response = await fetch('https://m0uxfja3eb.execute-api.us-west-2.amazonaws.com/prod/api/v2/upload-thumbnail', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        fileName: thumbnailFile.name,
                        fileContent: fileContent,
                        contentType: thumbnailFile.type,
                    }),
                });

                const result = await response.json();
                showModalWithText(result.message, null, false);
            } catch (error) {
                console.error('Error:', error);
                showModalWithText('Ocurrió un error durante la carga del thumbnail', null, false);
            } finally {
                setLoading(false);
            }
        };

        reader.readAsDataURL(thumbnailFile);
    };

    return (
        <div>
            <header className="fixed top-0 left-0 right-0 z-50 flex flex-col items-center w-full bg-gradient-to-r from-[#000000] via-[#141414] to-[#111111] p-4 h-25 md:flex-row md:justify-between">
                <img src={Logo} alt="Logo" className="h-auto w-52 md:mb-0 md:h-28 object-cover" />
                <button
                    onClick={() => navigate('/')}
                    className="bg-gradient-to-r from-[#000000] via-[#141414] to-[#111111] text-white px-4 py-2 rounded shadow hover:bg-[#333333]"
                >
                    Volver al inicio
                </button>
            </header>
            <div className="container mx-auto p-4 pt-44 flex flex-col items-center">
                <h2 className="text-2xl font-bold mb-4">Subir archivo PDF</h2>
                <input
                    type="file"
                    accept=".pdf"
                    onChange={handleFileChange}
                    className="mb-4 p-2 border border-gray-300 rounded"
                />
                <button
                    onClick={uploadFile}
                    className="bg-gradient-to-r from-[#000000] via-[#141414] to-[#111111] text-white px-4 py-2 rounded shadow hover:opacity-80"
                >
                    Subir archivo
                </button>

                <hr className="my-8 w-full border-t border-gray-400" />

                <h2 className="text-2xl font-bold mb-4">Subir imagen de portada</h2>
                <input
                    type="file"
                    accept="image/*"
                    onChange={handleThumbnailFileChange}
                    className="mb-4 p-2 border border-gray-300 rounded"
                />
                <button
                    onClick={handleThumbnailUpload}
                    className="bg-gradient-to-r from-[#000000] via-[#141414] to-[#111111] text-white px-4 py-2 rounded shadow hover:opacity-80"
                >
                    Subir Imagen de Portada
                </button>

                {loading && (
                    <div
                        className="inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                        role="status"
                    >
                        <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                            Loading...
                        </span>
                    </div>
                )}
                {uploadMessage && <p className="mt-4">{uploadMessage}</p>}
                <Modal />
            </div>
        </div>
    );
}

export default Admin;
